<template>
	<div class="h-full">
		<fixed-list :dataSource="dataSource" :config="config" @onDataSourceChange="onDataSourceChange"
			@onPageChange="onPageChange" :queryParam="queryParam">
			<div class="h-handle-button" slot="buttonGroup">
				<div class="h-handle-button">
					<el-button type="primary" size="small" @click="exportExcel"
						v-right-code="'Stockinfo:Exportstock'">导出库存</el-button>
				</div>
				<div class="h-handle-choose">
					&nbsp;&nbsp;&nbsp;&nbsp;<el-checkbox v-model="isShowZeroStock"
						@change="querynotZero">隐藏零库存货品</el-checkbox>
				</div>
				<div class="h-handle-choose">
					&nbsp;&nbsp;&nbsp;&nbsp;<el-checkbox v-model="isShowStopStock"
						@change="querynotStop">隐藏停用货品</el-checkbox>
				</div>
			</div>
			<p slot="elList">
				<el-table ref="stockinfoListTable" :data="dataSource.Result" border
					@selection-change="handleSelectionChange" highlight-current-row>
					<el-table-column v-for="(col,index,count)  in dataSource.ColDefs.BodyFieldParams" :key="index"
						:prop="col.FieldName" :label="col.DisplayName"
						:render-header="bindFilter(queryParam,col,ignoreField)"
						:fixed="index==0&&(!config||!config.isDetailDisplay)"
						:min-width="(!config||!config.isDetailDisplay)?parseInt(col.Width):0"
						v-if="col.Visible&&(!config||!config.isDetailDisplay||(config.isDetailDisplay&&index<2))">
						<template slot-scope="scope">
							<span v-if="col.FieldName==='ProductCode'">
								<el-button type="text" @click="batchDetail(scope.row)"
									style="text-decoration:underline;color:#1874CD;cursor:pointer">{{ scope.row[col.FieldName] }}</el-button>
							</span>
							<span v-else> {{ scope.row[col.FieldName] }}</span>
						</template>
					</el-table-column>
					<!-- <el-table-column label="操作" :min-width="130" v-if="!config.isDetailDisplay">
                        <template slot-scope="scope">
                            <el-button @click="seeProductImg(scope.row,scope.$index)" v-right-code="'Customer_Right_Stock_SummaryStockInfo_SearchSummaryStockInfo'" size="small" type="text">查看图片</el-button>
                            <div v-bind:class="'img'+ scope.$index" v-viewer v-show="false">
                                <img v-for="src in scope.row.ImgList" :src="src" :key="src">
                            </div>
                        </template>
                    </el-table-column> -->
				</el-table>
			</p>
		</fixed-list>
		<!--<el-dialog  :visible="productImgConfig.dialogVisible"
                   width="90%" :before-close="handleClose">
            <ProductImg :config="productImgConfig" ref="productImgSelect">
            </ProductImg>
        </el-dialog>-->
	</div>
</template>
<script>
	import sha from '../../../../../scripts/sha.js';
	export default {
		mounted() {
			this.Utils.lazy(() => {
				this.Event.$on("reloadPageList", () => this.reloadPageList());
			});
			var _this = this;
			this.initialize();
		},
		data() {
			return {
				ignoreField: [],
				multipleSelection: [],
				queryParam: {
					PageIndex: 1,
					PageSize: 10,
					Params: {
						zerostock: {
							fieldName: 'zerostock',
							fieldValue: true
						},
						stopstock: {
							fieldName: 'stopstock',
							fieldValue: true
						}
					}
				},
				dataSource: {
					ColDefs: {},
					Result: [],
					TotalCount: 0
				},
				stockDealOptions: {
					visible: false,
					size: 'large'
				},
				stockDealConfig: {
					isDetailDisplay: Object.freeze(true)
				},
				queryParamDetail: {
					pageIndex: 1,
					pageSize: 10,
					Params: {}
				},
				ProductCode: '',
				isShowZeroStock: true,
				isShowStopStock: true,
			}
		},
		props: {
			config: {
				isDetailDisplay: false
			},
			option: {}
		},
		methods: {
			reloadPageList: function() {
				this.initialize();
			},
			initialize() {
				this.onPageChange(this.queryParam);
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.$emit("onCheckRow", this.multipleSelection);
			},
			onPageChange(param) {
				var _this = this;
				this.queryParam = param;
				this.$ajax.query("omsapi/stockinfo/getlist", "post", param, data => {
					if (data.Result != null) {
						data.Result.forEach((item) => {
							item.ImgList = [];
						});
					}
					_this.dataSource = data;
				});
			},
			onDataSourceChange(ds) {
				var _this = this;

				_this.dataSource = {
					ColDefs: {
						BodyFieldParams: []
					},
					Result: [],
					TotalCount: 0
				};
				_this.$nextTick(function() {
					_this.dataSource = ds;

				});
			},
			onChangeEditDataSource(data) {
				this.config.isDetailDisplay = true;
				this.$emit("onChangeEditDataSource", data);
			},
			exportStockData() {
				if (this.multipleSelection == null || this.multipleSelection.length == 0) {
					this.Utils.messageBox(`没有需要导出的数据.`, "error");
					return false;
				}
			},
			batchDetail(row) {
				var data = {};
				data.StockDealInfoList = {};
				data.StockDealInfoList.Result = [];
				data.StockBatchInfoList = {};
				data.StockBatchInfoList.Result = [];
				data.ProductCode = row.ProductCode;
				data.WarehouseNo = row.WarehouseNo;
				data.InventoryType = row.InventoryType;
				this.Event.$emit("clearStockBatchForm");
				this.onChangeEditDataSource(data);
				this.Event.$emit("stockData", data);
			},
			stockDetail(row) {
				this.ProductCode = row.ProductCode;
				this.stockDealOptions.visible = true;
			},
			exportExcel: function() {
				/* var _this = this;
				var str = encodeURIComponent(JSON.stringify(_this.queryParam));
				var urlFile = "omsapi/stock/stockinfo/exportstock?queryParam=" + str;
				_this.Utils.export(url
				File); */
				var _this = this;
				var data = _this.queryParam;
				var str = encodeURIComponent(JSON.stringify(data));
				var urlFile = "omsapi/stockinfo/exportstock?queryParam=" + str + "&token=" + sha
			.getToken1();
				_this.Utils.export(urlFile);


			},
			seeProductImg: function(row, index) {
				var _this = this;
				var queryData = {
					productId: row.ProductID
				};
				_this.$ajax.send("omsapi/stock/stockinfo/getproductimg", "get", queryData, (data) => {
					if (data.Result != null) {
						if (data.Result.ImgList != undefined && data.Result.ImgList != null && data.Result
							.ImgList.length > 0) {
							_this.dataSource.Result[index].ImgList = data.Result.ImgList;
							setTimeout(() => {
								_this.$el.querySelector('.img' + index + '').$viewer.view(0);

							}, 100);
						} else {
							_this.dataSource.Result[index].ImgList = [];
							_this.Utils.messageBox("该商品没有图片数据");
						}
					} else {
						_this.dataSource.Result[index].ImgList = [];
						_this.Utils.messageBox("该商品没有图片数据");
					}
				});

			},
			handleClose: function() {
				var _this = this;
				_this.productImgConfig.ImgList = [];
				_this.productImgConfig.dialogVisible = false;
			},
			querynotZero: function(val) {
				this.queryParam.Params.zerostock = {
					fieldName: 'zerostock',
					fieldValue: val
				};
				this.onPageChange(this.queryParam);
			},
			querynotStop: function(val) {
				this.queryParam.Params.stopstock = {
					fieldName: 'stopstock',
					fieldValue: val
				};
				this.onPageChange(this.queryParam);
			}
		},
		components: {
			"ProductImg": resolve => {
				require(['./productimgpopup.vue'], resolve)
			}
		}
	}
</script>